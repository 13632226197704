import { Flex } from '@mantine/core';
import { useDocumentTitle } from '@mantine/hooks';
import { PropsWithChildren } from 'react';

export const AuthLayout = ({ children, title }: PropsWithChildren<{ title: string }>): JSX.Element => {
  useDocumentTitle(title);

  return (
    <Flex w="100%" h="100%" align="center" justify="center">
      {children}
    </Flex>
  );
};
